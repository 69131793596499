import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Amplify, Auth } from 'aws-amplify';

// Old User Pool
// Amplify.configure({
//   Auth: {
//       identityPoolId: 'eu-central-1:2bd71d9b-98bd-41a8-bf91-dad0a47e49b9',
//       region: 'eu-central-1',
//       userPoolId: 'eu-central-1_E5tsZ6Gcc',
//       userPoolWebClientId: '7uia9k5fvmailkg93l4tj68rmq',
      
//       oauth: {
//           domain: 'unity-domain-name.auth.eu-central-1.amazoncognito.com',
//           scope: ['email', 'openid', 'aws.cognito.signin.user.admin'],
//           redirectSignIn: 'https://unity-test-project.s3.eu-west-2.amazonaws.com/index.html',
//           redirectSignOut: 'https://unity-test-project.s3.eu-west-2.amazonaws.com/index.html',
//           responseType: 'code'
//       }
//   }
// });

Amplify.configure({
  Auth: {
      identityPoolId: 'us-east-2:74ff8c3b-b89f-4dd0-bf18-4e1d34576165',
      region: 'us-east-2',
      userPoolId: 'us-east-2_cSRzloXde',
      userPoolWebClientId: '6nmab3c7t74ggv06vena4p0b7r',

      oauth: {
          domain: 'jetquest.auth.us-east-2.amazoncognito.com',
          scope: ['email', 'openid', 'aws.cognito.signin.user.admin'],
          redirectSignIn: 'https://dev.jetquest.jetsoftpro.com',
          redirectSignOut: 'https://dev.jetquest.jetsoftpro.com',
          responseType: 'code'
      }
  }
});

// Prod User Pool
// Amplify.configure({
//   Auth: {
//       identityPoolId: 'us-east-2:bf617170-1104-4f52-88f5-2fbf83fb9070',
//       region: 'us-east-2',
//       userPoolId: 'us-east-2_cSRzloXde',
//       userPoolWebClientId: '1k4epunsupuraitbjckvlpm28n',
//
//       oauth: {
//           domain: 'jetquest.auth.us-east-2.amazoncognito.com',
//           scope: ['email', 'openid', 'aws.cognito.signin.user.admin'],
//           redirectSignIn: 'https://jetquest.jetsoftpro.com',
//           redirectSignOut: 'https://jetquest.jetsoftpro.com',
//           responseType: 'code'
//       }
//   }
// });

Auth.configure();

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
